import React from "react";
import { Box, keyframes } from "@mui/material";

const spinAnimation = keyframes`
  100% {
    transform: rotateY(360deg);
  }
`;

const PyramidLoader = () => {
  return (
    <Box
      sx={{
        position: "relative",
        width: "300px",
        height: "300px",
        display: "block",
        transformStyle: "preserve-3d",
        transform: "rotateX(-20deg)",
      }}
    >
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "100%",
          transformStyle: "preserve-3d",
          animation: `${spinAnimation} 4s linear infinite`,
        }}
      >
        {/* Side 1 */}
        <Box
          sx={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            transformOrigin: "center top",
            clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
            transform: "rotateZ(-30deg) rotateY(90deg)",
            background:
              "linear-gradient(135deg, #1E3A8A 25%, #3B82F6 25%, #3B82F6 50%, #1E3A8A 50%, #1E3A8A 75%, #3B82F6 75%)",
            border: "2px solid #000",
          }}
        />
        {/* Side 2 */}
        <Box
          sx={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            transformOrigin: "center top",
            clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
            transform: "rotateZ(30deg) rotateY(90deg)",
            background:
              "linear-gradient(135deg, #1E3A8A 25%, #3B82F6 25%, #3B82F6 50%, #1E3A8A 50%, #1E3A8A 75%, #3B82F6 75%)",
            border: "2px solid #000",
          }}
        />
        {/* Side 3 */}
        <Box
          sx={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            transformOrigin: "center top",
            clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
            transform: "rotateX(30deg)",
            background:
              "linear-gradient(135deg, #1E3A8A 25%, #3B82F6 25%, #3B82F6 50%, #1E3A8A 50%, #1E3A8A 75%, #3B82F6 75%)",
            border: "2px solid #000",
          }}
        />
        {/* Side 4 */}
        <Box
          sx={{
            width: "100px",
            height: "100px",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            transformOrigin: "center top",
            clipPath: "polygon(50% 0%, 0% 100%, 100% 100%)",
            transform: "rotateX(-30deg)",
            background:
              "linear-gradient(135deg, #1E3A8A 25%, #3B82F6 25%, #3B82F6 50%, #1E3A8A 50%, #1E3A8A 75%, #3B82F6 75%)",
            border: "2px solid #000",
          }}
        />
        {/* Shadow */}
        <Box
          sx={{
            width: "80px",
            height: "80px",
            background: "#8B5AD5",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            margin: "auto",
            transform: "rotateX(90deg) translateZ(-40px)",
            filter: "blur(12px)",
          }}
        />
      </Box>
    </Box>
  );
};

export default PyramidLoader;

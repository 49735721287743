import React, { useState } from "react";
import {
  Grid,
  Container,
  Typography,
  Button,
  Box,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Pagination,
  Collapse,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import MenuIcon from "@mui/icons-material/Menu";
import { keyframes } from "@emotion/react";
import logo from "../../assets/banner.png";
import { productos } from "./Productos";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Footer from "../Footer";
import PyramidLoader from "../PyramidLoader";

// Animación de icono
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const CardProduct = () => {
  const [subcategoriasAbiertas, setSubcategoriasAbiertas] = useState({});
  const [subcategoriaSeleccionada, setSubcategoriaSeleccionada] = useState("");
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState(""); // Nueva variable para la categoría seleccionada
  const [paginaActual, setPaginaActual] = useState(1);
  const [categoriasAbiertas, setCategoriasAbiertas] = useState(false); // Nuevo estado para controlar la visibilidad de categorías
  const productosPorPagina = 6;

  const categorias = [...new Set(productos.map((p) => p.categoria))];

  const subcategoriasPorCategoria = {};
  productos.forEach((producto) => {
    if (producto.subcategoria) {
      if (!subcategoriasPorCategoria[producto.categoria]) {
        subcategoriasPorCategoria[producto.categoria] = new Set();
      }
      subcategoriasPorCategoria[producto.categoria].add(producto.subcategoria);
    }
  });

  // Convertir el Set a un Array
  Object.keys(subcategoriasPorCategoria).forEach((key) => {
    subcategoriasPorCategoria[key] = [...subcategoriasPorCategoria[key]];
  });

  // Filtrar productos
  let productosFiltrados;

  if (categoriaSeleccionada) {
    productosFiltrados = productos.filter(
      (p) => p.categoria === categoriaSeleccionada
    );

    if (subcategoriaSeleccionada) {
      productosFiltrados = productosFiltrados.filter(
        (p) => p.subcategoria === subcategoriaSeleccionada || !p.subcategoria
      );
    }
  } else {
    productosFiltrados = productos;
  }

  const cantidadPaginas = Math.ceil(
    productosFiltrados.length / productosPorPagina
  );

  const productosPagina = productosFiltrados.slice(
    (paginaActual - 1) * productosPorPagina,
    paginaActual * productosPorPagina
  );

  const generarLinkWhatsApp = (descripcion) => {
    const numero = "+593967667366";
    const mensaje = encodeURIComponent(
      `Hola, estoy interesado en el producto: ${descripcion}`
    );
    return `https://wa.me/${numero}?text=${mensaje}`;
  };

  const manejarCambioPagina = (event, valor) => {
    setPaginaActual(valor);
  };

  const manejarClicCategoria = (categoria) => {
    setCategoriaSeleccionada(categoria);
    setSubcategoriasAbiertas((prev) => ({
      ...prev,
      [categoria]: !prev[categoria],
    }));
    setSubcategoriaSeleccionada("");
    setPaginaActual(1);
  };

  const manejarClicSubcategoria = (subcategoria) => {
    setSubcategoriaSeleccionada(subcategoria);
    setPaginaActual(1);
  };

  const manejarMostrarTodos = () => {
    setCategoriaSeleccionada("");
    setSubcategoriaSeleccionada("");
    setPaginaActual(1);
  };

  const manejarToggleCategorias = () => {
    setCategoriasAbiertas((prev) => !prev);
  };

  return (
    <Container
      id="tienda"
      sx={{
        pt: { xs: 4, sm: 12 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 16,
          left: 16,
        }}
      >
        <a href="/" style={{ textDecoration: "none" }}>
          <img
            src={logo}
            alt="Logo"
            style={{
              width: "140px",
              height: "auto",
              cursor: "pointer",
              borderRadius: "5px",
              paddingTop: 4,
            }}
          />
        </a>
      </Box>

      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: "100%", md: "60%" },
          marginTop: { xs: 5, sm: 5, md: 0 },
          textAlign: "center",
          fontFamily: "FuturaStd-Extrabold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <StoreIcon
          sx={{
            color: "primary.main",
            mr: 1,
            animation: `${bounceAnimation} 2s infinite`,
          }}
        />
        Tienda
      </Typography>

      <IconButton
        sx={{ display: { xs: "block", sm: "none" }, mb: 2 }}
        onClick={manejarToggleCategorias}
      >
        <MenuIcon />
      </IconButton>

      {/* Estructura para categorías y pirámide */}
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8} md={9}>
          <Box
            sx={{
              display: {
                xs: categoriasAbiertas ? "block" : "none",
                sm: "flex",
              },
              flexDirection: "column",
              gap: 2,
              mb: 4,
            }}
          >
            <Button variant="outlined" onClick={manejarMostrarTodos} fullWidth>
              Todos los Productos
            </Button>
            {categorias.map((categoria) => (
              <Box key={categoria}>
                <Button
                  variant="outlined"
                  onClick={() => manejarClicCategoria(categoria)}
                  fullWidth
                  sx={{ my: { xs: 1, sm: 0, md: 0 } }}
                >
                  {categoria}
                </Button>
                <Collapse in={subcategoriasAbiertas[categoria]}>
                  <Box sx={{ pl: 2 }}>
                    {subcategoriasPorCategoria[categoria] &&
                      subcategoriasPorCategoria[categoria].length > 0 &&
                      [...subcategoriasPorCategoria[categoria]].map(
                        (subcategoria) => (
                          <Button
                            key={subcategoria}
                            variant={
                              subcategoriaSeleccionada === subcategoria
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              manejarClicSubcategoria(subcategoria)
                            }
                            sx={{ my: 1, mr: 1 }}
                          >
                            {subcategoria}
                          </Button>
                        )
                      )}
                  </Box>
                </Collapse>
              </Box>
            ))}
          </Box>
        </Grid>

        {/* Pirámide al lado derecho de las categorías */}
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <PyramidLoader />
        </Grid>
      </Grid>

      <Grid container spacing={4}>
        {productosPagina.map((producto, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card
              sx={{
                boxShadow: "0px 4px 10px #7FAE40",
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0px 8px 20px #6A9920",
                },
              }}
            >
              <CardMedia
                component="img"
                height="250"
                image={producto.imagen}
                alt={producto.descripcion}
              />
              <CardContent>
                <Typography fontFamily={"FuturaStd-Medium"}>
                  {producto.descripcion}
                </Typography>
                <IconButton
                  color="success"
                  href={generarLinkWhatsApp(producto.descripcion)}
                  target="_blank"
                  sx={{ mt: 1 }}
                >
                  <WhatsAppIcon />
                </IconButton>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {cantidadPaginas > 1 && (
        <Box sx={{ mt: 4 }}>
          <Pagination
            count={cantidadPaginas}
            page={paginaActual}
            onChange={manejarCambioPagina}
            color="primary"
          />
        </Box>
      )}

      <Box
        sx={{
          marginTop: "auto",
          width: { xs: "110%", sm: "110%", md: "105%", lg: "150%" },
        }}
      >
        <Footer />
      </Box>
    </Container>
  );
};

export default CardProduct;

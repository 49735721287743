import foto1 from "./assetsProductos/panel1.png";
import foto2 from "./assetsProductos/panel2.png";
import foto3 from "./assetsProductos/bateria1.png";
import foto4 from "./assetsProductos/accesorio1.png";
import foto5 from "./assetsProductos/inversor1.png";
import foto6 from "./assetsProductos/inversor2.png";
import foto7 from "./assetsProductos/bateria2.png";
import foto8 from "./assetsProductos/bateria3.png";
import foto9 from "./assetsProductos/luz1.png";
import foto10 from "./assetsProductos/luz2.png";
import foto11 from "./assetsProductos/luz3.png";
import foto12 from "./assetsProductos/electrico1.png";
import foto13 from "./assetsProductos/seguridad1.png";

export const productos = [
  {
    categoria: "Energía Solar",
    subcategoria: "Paneles Solares",
    imagen: foto1,
    descripcion: "PANEL SOLAR MONOCRISTALINO 50W",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Inversores",
    imagen: foto6,
    descripcion: "INVERSOR IPOWER 1200 W, ENTRADA: 24VDC, SALIDA: 120 VCA",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Accesorios",
    imagen: foto4,
    descripcion: "CABLE DE COMUNICACIÓN CONTROLADORES",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Paneles Solares",
    imagen: foto2,
    descripcion: "PANEL SOLAR MONOCRISTALINO 550W",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Baterías",
    imagen: foto7,
    descripcion: "BATERIAS GEL 12VDC 9 AH PARA SISTEMA SOLAR",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Baterías",
    imagen: foto3,
    descripcion: "BATERIAS GEL 12VDC 100 AH PARA SISTEMA SOLAR",
  },
  {
    categoria: "Energía Solar",
    subcategoria: "Baterías",
    imagen: foto8,
    descripcion: "BATERIAS GEL 12VDC 150 AH PARA SISTEMA SOLAR",
  },

  {
    categoria: "Energía Solar",
    subcategoria: "Inversores",
    imagen: foto5,
    descripcion: "AXE 5.0L-C1 GROWATT BATTERY HUB",
  },

  //Iluminarias
  {
    categoria: "Iluminarias",
    subcategoria: "Luminaria Ojo de Buey LED",
    imagen: foto9,
    descripcion: "LUMINARIA LED CUADRADA 12 WATTS ( CCT: 3000K , 4000K, 6500K)",
  },
  {
    categoria: "Iluminarias",
    subcategoria: "Luminaria Ojo de Buey LED",
    imagen: foto10,
    descripcion:
      "LUMINARIA LED OJO DE BUEY REDONDA 12 WATTS ( CCT: 3000K , 4000K, 6500K) ",
  },
  {
    categoria: "Iluminarias",
    subcategoria: "Luminaria Ojo de Buey LED",
    imagen: foto11,
    descripcion: "PANEL LED 60X60 45W CCT:6000K 85-270Vac",
  },

  //Material electrico
  {
    categoria: "Material Eléctrico",
    imagen: foto12,
    descripcion: "BAQUELITA PARA BASE SOCKET 100A v",
  },
  //Seguridad electrónica
  {
    categoria: "Seguridad Electrónica",
    imagen: foto13,
    descripcion: "BOTÓN DE APERTURA PARA SISTEMAS DE ACCESO",
  },
];

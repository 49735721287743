import React from "react";
import {
  Container,
  createTheme,
  ThemeProvider,
  Typography,
  Box,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import StoreIcon from "@mui/icons-material/Store";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

// Agregar el CSS de slick-carousel aquí o en tu archivo global CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import getLPTheme from "../getLPTheme";
import { productos } from "./Productos/Productos";

export default function TiendaCarrusel() {
  const [mode, setMode] = React.useState("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const navigate = useNavigate();

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // Definir la animación de arriba hacia abajo
  const bounceAnimation = keyframes`
    0%, 100% {
      transform: translateY(0);  // Posición inicial y final
    }
    50% {
      transform: translateY(-10px); // Mueve el icono hacia arriba 10px
    }
  `;

  // Configuración del carrusel
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleCardClick = () => {
    navigate("/tienda");
  };

  // Obtener solo los primeros 5 productos
  const topProducts = productos.slice(0, 5);

  return (
    <ThemeProvider theme={!showCustomTheme ? LPtheme : defaultTheme}>
      <Container
        id="tienda"
        sx={{
          pt: { xs: 4, sm: 12 },
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Typography
          component="h2"
          variant="h4"
          color="text.primary"
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "center", md: "center" },
            fontFamily: "FuturaStd-Extrabold",
            display: "flex", // Añade flex para alinear el icono y el texto
            alignItems: "center", // Centra verticalmente el contenido
            justifyContent: "center", // Centra horizontalmente el contenido
          }}
        >
          <StoreIcon
            sx={{
              color: "primary.main",
              mr: 1,
              animation: `${bounceAnimation} 2s infinite`, // Aplica la animación
            }}
          />{" "}
          Tienda
        </Typography>

        <Box
          sx={{
            width: { md: "100%", sm: "90%", xs: "90%" },
          }}
        >
          <Slider {...settings}>
            {topProducts.map((product) => (
              <Box
                sx={{
                  marginRight: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  p: 2,
                  cursor: "pointer",
                  border: "1px solid #90CAF9",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                  "&:hover": {
                    boxShadow: "0 6px 12px rgba(0,0,0,0.3)",
                  },
                }}
                onClick={handleCardClick}
              >
                <img
                  src={product.imagen}
                  alt={product.descripcion}
                  style={{
                    width: "90%",
                    height: "200px",
                    borderRadius: "8px",
                    marginLeft: 17,
                  }}
                />
                <Typography
                  sx={{
                    mt: 2,
                    fontFamily: "FuturaStd-Medium",
                    marginLeft: 2,
                    textTransform: "capitalize",
                  }}
                >
                  {product.descripcion}
                </Typography>
              </Box>
            ))}
          </Slider>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

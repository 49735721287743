import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getLPTheme from "../getLPTheme";
import AppAppBar from "./AppAppBar";
import Features from "./Features";
import FAQ from "./FAQ";
import Footer from "./Footer";
import Hero from "./Hero";
import TiendaCarrusel from "./TiendaCarrusel";

const LandingPage = () => {
  const [mode, setMode] = React.useState("dark");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const [showCarousel, setShowCarousel] = React.useState(false);

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === "dark" ? "light" : "dark"));
  };

  const toggleCarousel = () => {
    setShowCarousel((prev) => !prev);
  };

  return (
    <ThemeProvider theme={!showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar
        mode={mode}
        toggleColorMode={toggleColorMode}
        toggleCarousel={toggleCarousel}
      />
      <Hero showCarousel={showCarousel} />
      <Box sx={{ bgcolor: "background.default" }}>
        <Divider />
        <Features />
        <Divider />
        <FAQ />
        <Divider />
        <TiendaCarrusel />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
